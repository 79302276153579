import React from 'react'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import InputValidation from 'components/InputValidation';
import { Label } from 'components/ui/label';
// import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, DropdownMenu } from 'components/ui/dropdown-menu';
// import { Button } from 'components/ui/button';
// import { ChevronDown } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import InstructionSelectionModal from '../StepLLM/InstructionSelectionModal';
import NextStep from '../NextStep';
// import { Input } from 'components/ui/input';
import DynamicInputSelector from '../DynamicInputSelector';
// import { Button } from 'components/ui/button';
// import { ChevronDownIcon } from 'lucide-react';
import OutputMapSelector from '../OutputMapSelector';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"
// import { Button } from 'components/ui/button';
import { AlertCircle } from 'lucide-react';

const StepLLM = ({ inputGroup, index, inputGroups, setInputGroups, getPreviousOutputs, getNextSteps}) => {


    const handleInputChange = (field, value) => {
            const newInputGroups = [...inputGroups];

            if (field === 'unique_name') {
                newInputGroups[index].unique_name = value;
            } else if (field === 'model_type') {
                newInputGroups[index].model_type = value;
                newInputGroups[index].unique_name = ""; 
            } else if (field === 'output') {
                newInputGroups[index].output.Output = value.trim();
            } else if (field === 'output_map') {
                newInputGroups[index].output.Output_map = value;
            } else if (field === 'input') {
                newInputGroups[index].input = value; 
                // newInputGroups[index].dynamic_inputs_ids = stepsIds
            } 
            setInputGroups(newInputGroups);
    };

    const extractOutputValues =(text) => {
        const regex = /output\[['"]([a-zA-Z0-9_]+)['"]\]/g;
        let matches;
        const result = [];
        
        while ((matches = regex.exec(text)) !== null) {
            const value = matches[1];
            if (!result.includes(value)) {
            result.push(value);
            }
        }
        
        return result;
        }

    // const handleMultiSelectChange = (selectedValues) => {
    //     handleInputChange("dynamic_inputs", selectedValues.values, selectedValues.stepsIds);
    //     console.log(selectedValues)
    // };

    // const handleCheckChanged = (checked, item) => {
    //     console.log(item)
    //     const newInputs = checked
    //         ? 
    //             {
    //                 values:[...(inputGroup.dynamic_inputs || []), item.prevOutp.value], 
    //                 stepsIds:[
    //                     ...(inputGroup.dynamic_inputs_ids || []), 
    //                     {id:item.stepId, output:item.prevOutp.value, stepIndex:item.stepIndex}
    //                 ]
    //             }
    //         : 
    //             {
    //                 values:(inputGroup.dynamic_inputs || []).filter(input => input !== item.prevOutp.value), 
    //                 stepsIds:(inputGroup.dynamic_inputs_ids || []).filter(input => {
    //                     if(input.stepIndex === 0){
    //                         return input.output !== item.prevOutp.value 
    //                     }

    //                     return (input.id !== item.stepId && input.output !== item.prevOutp.value )
    //                 })
    //             };

    //     handleMultiSelectChange(newInputs);
    // }
    
    // Extract dynamic inputs from the first step
    // const getFirstStepDynamicInput = () => {
    //     const firstStep = inputGroups[0];
    //     let firstStepDynamicInput = [];
    //     if (firstStep?.step_type === "Identification") {
    //         firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
    //             stepIndex:0,
    //             stepId:inputGroups[0]?.step_id,
    //             prevOutp:{
    //                 value,
    //                 label: "Step 2 Input",
    //             }
    //         })) || [];
    //     } 

    //     return firstStepDynamicInput
    // }

    // Combine previous outputs with the first step's dynamic input
    // const multiSelectOptions = [
    //     // ...getFirstStepDynamicInput(),
    //     { prevOutp: { value: "context", label: "Context" }, isContext: true },
    //     ...getPreviousOutputs(index),
    // ];

    return (
        <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
                <AccordionTrigger>Instruction Configuration</AccordionTrigger>
                <AccordionContent className="p-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <Label htmlFor={`modelType-${index}`}>Instruction Type</Label>
                            <Select onValueChange={(value) => handleInputChange("model_type", value)} value={inputGroup?.model_type || ""}>
                                <SelectTrigger className="w-full bg-white overflow-hidden">
                                    <SelectValue placeholder="-" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="IBM">IBM</SelectItem>
                                    <SelectItem value="Groq">Groq</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div >
                            <Label htmlFor={`uniqueName-${index}`}>Instruction Name</Label>
                            <InstructionSelectionModal
                                group={inputGroup}
                                index={index}
                                inputGroups={inputGroups}
                                setInputGroups={setInputGroups}
                                model_type={inputGroup?.model_type}
                                />
                        </div>
                    </div>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
                <AccordionTrigger>Input Configuration</AccordionTrigger>
                <AccordionContent className="p-2">
                    <Label htmlFor={`input-${index}`} className="flex items-center gap-2">
                        Step Input
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    {/* <Button variant="ghost" size="icon" className="flex-shrink-0 hover:bg-gray-50">
                                    </Button> */}
                                    <AlertCircle className="h-4 w-4 cursor-pointer" />
                                </TooltipTrigger>
                                <TooltipContent className="max-w-xs">
                                    <p className="text-sm">
                                        Enter your text and add custom context fields and previous outputs to include in your input. Custom context
                                        fields can be added, and you can select from available previous outputs.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </Label>
                    <DynamicInputSelector 
                    previousOutputs={getPreviousOutputs(index)} 
                    onCombinedResultChange={(result) => handleInputChange("input", result) }
                    inputGroup={inputGroup}
                    inputText={inputGroup?.input}
                    />
                </AccordionContent>
            </AccordionItem>
            {inputGroup?.step_type !== "Identification" && (
                <AccordionItem value="item-3">
                    <AccordionTrigger>Output Configuration</AccordionTrigger>
                    <AccordionContent className="p-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <Label htmlFor={`output-${index}`}>Step Output</Label>
                                <InputValidation
                                    id={`output-${index}`}
                                    value={inputGroup?.output.Output || ""}
                                    onChange={(e) => handleInputChange("output", e.target.value)}
                                    className="bg-white"
                                    validation={handler.validateOutputName}
                                    disabled={
                                        inputGroup?.output.Output !== "" && (
                                            // For LLM steps
                                            inputGroups.slice(index + 1).some(
                                                nextGroup => (nextGroup.step_type === "LLM" || nextGroup.step_type === "LLM-Stream")  && extractOutputValues(nextGroup.input).find(outp => outp === inputGroup.output.Output)
                                            )
                                            ||
                                            // For Non-LLM steps
                                            inputGroups.slice(index + 1).some(
                                                nextGroup => nextGroup.step_type === "Non-LLM" && extractOutputValues(nextGroup.input.query_text).find(outp => outp === inputGroup.output.Output)
                                            )
                                            // ||
                                            // For Non-LLM steps
                                            // inputGroups.slice(index + 1).some(
                                            //     nextGroup => nextGroup.step_type === "Non-LLM - BeX Insights" && nextGroup.input?.includes(inputGroup.output.Output)
                                            // )
                                        )
                                    }
                                />
                            </div>
                            <div >
                                <Label htmlFor={`output-${index}`} className="flex items-center gap-2">
                                    Step Output Map
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                {/* <Button variant="ghost" size="icon" className="flex-shrink-0">
                                                </Button> */}
                                                <AlertCircle className="h-4 w-4 cursor-pointer" />
                                            </TooltipTrigger>
                                            <TooltipContent className="max-w-xs">
                                                <p className="text-sm">
                                                    Select outputs to create an output map. This map will be added to the final result without processing.
                                                    {/* allowing you to reference previous outputs in your current step. */}
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </Label>
                                <OutputMapSelector 
                                previousOutputs={getPreviousOutputs(index)} 
                                onOutputMapChange={(result) => handleInputChange("output_map", result) }
                                inputGroup={inputGroup}
                                />
                            </div>
                            <div >
                                <NextStep
                                getNextSteps={getNextSteps}
                                inputGroup={inputGroup}
                                inputGroups={inputGroups}
                                setInputGroups={setInputGroups}
                                index={index}
                                />
                            </div>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            )}
        </Accordion>   
    );
}

export default StepLLM


  {/* <div className={`${nested? "col-span-3" : "col-span-4"}`}>
                <Label htmlFor={`input-${index}`}>Step Input</Label>
                <div className='flex items-center gap-2'>
                    <Input
                        id={`dynamicInput-${index}`}
                        value={""}
                        placeholder="input..."
                        className="bg-white"
                    />
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" role="combobox" className="w-full justify-between bg-white">
                                {inputGroup?.dynamic_inputs?.length > 0 ? `${inputGroup.dynamic_inputs.length} selected` : "Select inputs"}
                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-[200px]">
                            {multiSelectOptions.map((item) => (
                                <DropdownMenuCheckboxItem
                                    key={item.prevOutp.value}
                                    checked={inputGroup?.dynamic_inputs?.includes(item.prevOutp.value)}
                                    onCheckedChange={(checked)=> handleCheckChanged(checked, item)}
                                >
                                    {item.prevOutp.label}
                                </DropdownMenuCheckboxItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div> */}