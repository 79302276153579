import React, { useCallback, useRef } from "react"
import { useState, useEffect } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { ChevronDownIcon, PlusIcon, XIcon, AlertCircle } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "components/ui/dialog"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, DropdownMenu, DropdownMenuLabel } from 'components/ui/dropdown-menu';


export default function DynamicInputSelector({ onCombinedResultChange, previousOutputs, inputGroup, inputText }) {

    const [userInput, setUserInput] = useState("")
    const [selectedOutputs, setSelectedOutputs] = useState([])
    const [contextFields, setContextFields] = useState([])
    const [newContextField, setNewContextField] = useState("")
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const isFirstRender = useRef(true)

    useEffect(() => {
        // if(inputGroup.step_id && inputText){
            const result = {
                state: "",
                context: [],
                output: [],
              }
            
                const colonIndex = inputText.indexOf(":")
                if (colonIndex !== -1) {
                    result.state = inputText.slice(0, colonIndex).trim()
                }

                // Extract context items
                const contextRegex = /{{context\['(.*?)'\]}}/g
                let match
                while ((match = contextRegex.exec(inputText)) !== null) {
                    result.context.push(match[1])
                }

                // Extract output items
                const outputRegex = /{{output\['(.*?)'\]}}/g
                while ((match = outputRegex.exec(inputText)) !== null) {
                    result.output.push(match[1])
                }

              console.log(result)
              setUserInput(result.state || "")
              setContextFields(result.context || [])
              setSelectedOutputs(result.output || [])
        // }
     
    }, [inputText])
    
  
    const updateResult = useCallback(() => {
        let result = userInput.trim()
        if (result && !result.endsWith(":")) {
          result += ":"
        }
        const contextPart = contextFields.map((field) => `{{context['${field}']}}`).join(" ")
        const outputPart = selectedOutputs.map((output) => `{{output['${output}']}}`).join(" ")
        const selectionPart = `${contextPart} ${outputPart}`.trim()
        if (selectionPart) {
          result += ` ${selectionPart}`
        }
        return result
      }, [userInput, contextFields, selectedOutputs])
    
      useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
          }
        const result = updateResult()
        onCombinedResultChange(result)
      }, [updateResult])
    
      const handleInputChange = (e) => {
        setUserInput(e.target.value)
      }
    
      const handleOutputToggle = (output) => {
        setSelectedOutputs((prev) => (prev.includes(output) ? prev.filter((item) => item !== output) : [...prev, output]))
      }
    
      const addContextField = () => {
        if (newContextField && !contextFields.includes(newContextField)) {
          setContextFields([...contextFields, newContextField])
          setNewContextField("")
        }
      }
    
      const removeContextField = (field) => {
        setContextFields(contextFields.filter((f) => f !== field))
      }
    
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-6">
            <Input value={userInput} onChange={handleInputChange} placeholder="Enter your text here..." className="w-full bg-white" />
            <Button variant="outline" className="w-full" onClick={() => setIsDialogOpen(true)}>
                Custom Context
            </Button>
            {inputGroup?.step_type !== "Identification" &&
                <DropdownMenu className="w-full">
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="w-full justify-between">
                            <span className="truncate">
                                {selectedOutputs.length > 0 
                                    ? `Selected: ${selectedOutputs.length }`
                                    : "Select outputs"}
                            </span>
                            <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>Previous Outputs</DropdownMenuLabel>
                        {previousOutputs.map((output) => (
                            <DropdownMenuCheckboxItem
                            key={output.stepId}
                            checked={selectedOutputs.includes(output.prevOutp.value)}
                            onCheckedChange={() => handleOutputToggle(output.prevOutp.value)}
                            >
                                {output.prevOutp.label}
                            </DropdownMenuCheckboxItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            }

                        
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add Context Fields</DialogTitle>
                        <DialogDescription>Enter custom context field names to include in your selection.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div className="flex space-x-2">
                            <Input
                            value={newContextField}
                            onChange={(e) => setNewContextField(e.target.value.trim())}
                            placeholder="Enter context field"
                            />
                            <Button onClick={addContextField}>
                                <PlusIcon className="h-4 w-4" /> Add
                            </Button>
                        </div>
                        <div className="space-y-2">
                            {contextFields.map((field) => (
                                <div key={field} className="flex items-center justify-between bg-accent/50 p-2 rounded">
                                    <span>{field}</span>
                                    <Button variant="ghost" size="sm" onClick={() => removeContextField(field)}>
                                        <XIcon className="h-4 w-4" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
    }
    
    