import FileManager from 'components/FileManager/FileManager'
import { SubOrgContext } from 'context/SubOrganizationContext'
import React, { useContext, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import { Layers } from 'lucide-react';
import ProcessModal from './ProcessModal';
import { L2ProcessingDialog } from './L2ProcessingModal';
import { useParams } from 'react-router-dom';
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout';

export default function FileManagerDocument() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessing2, setIsProcessing2] = useState(false);
    const [selectedItems, setSelectedItems] = useState([])
    
    const [processModal, setProcessModal] = useState(false)
    
    const { collections } = useContext(SubOrgContext)
    const { oragID, subOragID } = useParams()

    const handleOpenProcessModal = () => {
        setProcessModal(true)
    };

    const ACTION_COMPONENTS = [
        {id: 1, name: 'L1 Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenProcessModal},
        {id: 2, name: `L2 Processing ${`(${selectedItems?.length || 0})` }`, icon: <Layers className="mr-2 h-4 w-4" />, onclick: () => setIsProcessing2(true)},
    ]

    return (
        <NestedPageLayout>
            <div className='my-3'>
                <Breadcrumb maxPaths={3} />
            </div>

            <FileManager
                collections={collections}
                actionComponents={ACTION_COMPONENTS}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                />

            <ProcessModal organizationId={oragID} subOrganizationId={subOragID} isOpen={processModal} setIsOpen={setProcessModal} loading={isProcessing} setLoading={setIsProcessing}/>
            
            {/* l2 processing  */}
            <L2ProcessingDialog isOpen={isProcessing2} setIsOpen={setIsProcessing2} selectedItems={selectedItems}/>

        </NestedPageLayout>
    )
}
