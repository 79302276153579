import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';
import { Label } from "components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import React, {useEffect, useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion"
import StepNonLLMAPI2 from './StepNonLLMAPI2';
import NextStep from '../NextStep';
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"
import { AlertCircle } from 'lucide-react';
import DynamicInputSelector from '../DynamicInputSelector';
import InputValidation from 'components/InputValidation';

const StepNonLLMAPI = ({inputGroup, index, inputGroups, setInputGroups, getPreviousOutputs, getNextSteps}) => {
    const [apis, setApis] = useState([]);
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getFunctionRegistry();
                if (res.data && typeof res.data === 'object') {
                    console.log(res.data)
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data).filter(key => key === "KNOWLEDGE_RETRIEVAL_API"));

                    // Set properties if a function is already selected
                    // if (inputGroup?.registry_key) {
                        setSelectedFunctionProps(res.data[inputGroup.registry_key]?.properties || {});
                    // }
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, []);

    const extractOutputValues =(text) => {
        const regex = /output\[['"]([a-zA-Z0-9_]+)['"]\]/g;
        let matches;
        const result = [];
        
        while ((matches = regex.exec(text)) !== null) {
            const value = matches[1];
            if (!result.includes(value)) {
            result.push(value);
            }
        }
        
        return result;
        }

    // const firstStep = inputGroups[0];
    // let firstStepDynamicInput = [];
    // if (firstStep?.step_type === "Identification") {
    //     firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
    //         stepIndex:0,
    //         stepId:inputGroups[0]?.step_id,
    //         prevOutp:{
    //         value,
    //         label: "Step 2 Input",
    //         }
    //     })) || [];
    // } 
    // else if (firstStep?.step_type === "Non-LLM" && firstStep.dynamic_inputs?.query_text) {
    //     firstStepDynamicInput = [
    //         {
    //             stepIndex:0,
    //             stepId:inputGroups[0]?.step_id,
    //             prevOutp:{
    //             value: firstStep.dynamic_inputs.query_text,
    //             label: "Step 2 Input",
    //             }
    //         },
    //     ];
    // } else if (firstStep?.step_type === "Non-LLM - BeX Insights") {
    //     firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
    //         stepIndex:0,
    //         stepId:inputGroups[0]?.step_id,
    //         prevOutp:{
    //         value,
    //         label: "Step 2 Input",
    //         }
    //     })) || [];
    // }

    // Combine previous outputs with the first step's dynamic input
    // const inputSelection = [
    //     // ...firstStepDynamicInput,
    //     ...getPreviousOutputs(index),
    // ];

    const handleInputChange = (field, value) => {
        const newInputGroups = [...inputGroups];
        if (field === "registry_key") {
            newInputGroups[index].registry_key = value;
            setSelectedFunctionProps(functionRegistry[value]?.properties || {});
        } else if (field === "output") {
            newInputGroups[index].output = {[value.trim()]:"compiled_text"};
        } else if (selectedFunctionProps[field] === "fixed") {
            if (field === "top_k" || field === "top_n" || field === "offset") {
                newInputGroups[index].input[field] = parseInt(value) || 0;
            } else if (field === "use_rerank") {
                newInputGroups[index].input[field] = value === "true";
            } else {
                newInputGroups[index].input[field] = field === "collection_id"? value?._id : value;
            }
        } else if (selectedFunctionProps[field] === "dynamic") {
            newInputGroups[index].input[field] = value;
        }
        setInputGroups(newInputGroups);
    };
    
    return (
        <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
                <AccordionTrigger>RAG Configuration</AccordionTrigger>
                <AccordionContent className="p-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <Label htmlFor={`functionName-${index}`}>API Name</Label>
                            <Select
                                onValueChange={(value) => handleInputChange("registry_key", value)}
                                value={inputGroup?.registry_key || ""}
                            >
                                <SelectTrigger className="w-full bg-white">
                                    <SelectValue placeholder="Select an API" />
                                </SelectTrigger>
                                <SelectContent>
                                    {apis.map((funcName) => (
                                        <SelectItem key={funcName} value={funcName}>
                                            {funcName}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <StepNonLLMAPI2
                        getPreviousOutputs={getPreviousOutputs}
                        inputGroup={inputGroup}
                        index={index}
                        handleInputChange={handleInputChange}
                        inputGroups={inputGroups}
                        selectedFunctionProps={selectedFunctionProps}
                        />  
                    </div>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
                <AccordionTrigger>Input Configuration</AccordionTrigger>
                <AccordionContent className="p-2">
                    <Label htmlFor={`input-${index}`} className="flex items-center gap-2">
                        Step Input
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <AlertCircle className="h-4 w-4 cursor-pointer" />
                                </TooltipTrigger>
                                <TooltipContent className="max-w-xs">
                                    <p className="text-sm">
                                        Enter your text and add custom context fields and previous outputs to include in your input. Custom context
                                        fields can be added, and you can select from available previous outputs.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </Label>
                    <DynamicInputSelector 
                    previousOutputs={getPreviousOutputs(index)} 
                    onCombinedResultChange={(result) => handleInputChange("query_text", result) }
                    inputGroup={inputGroup}
                    inputText={inputGroup.input.query_text}
                    />
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
                <AccordionTrigger>Output Configuration</AccordionTrigger>
                <AccordionContent className="p-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div >
                            <Label htmlFor={`output-${index}`}>Step Output</Label>
                            <InputValidation
                                id={`output-${index}`}
                                value={ Object.keys(inputGroup?.output)[0]  || ""}
                                onChange={(e) => handleInputChange("output", e.target.value)}
                                className="bg-white"
                                validation={handler.validateOutputName}
                                disabled={
                                    Object.keys(inputGroup?.output)[0].trim() !== "" && (
                                        // For LLM steps
                                        inputGroups.slice(index + 1).some(
                                            nextGroup => (nextGroup.step_type === "LLM" || nextGroup.step_type === "LLM-Stream") && extractOutputValues(nextGroup.input).find(outp => outp === Object.keys(inputGroup?.output)[0])
                                        )
                                        ||
                                        // For Non-LLM steps
                                        inputGroups.slice(index + 1).some(
                                            nextGroup => nextGroup.step_type === "Non-LLM" && extractOutputValues(nextGroup.input.query_text).find(outp => outp === Object.keys(inputGroup?.output)[0])
                                        )
                                        // ||
                                        // For Non-LLM steps
                                        // inputGroups.slice(index + 1).some(
                                        //     nextGroup => nextGroup.step_type === "Non-LLM - BeX Insights" && nextGroup.input?.includes(Object.keys(inputGroup?.output)[0])
                                        // )
                                    )
                                }
                            />
                        </div>
                        <div>
                            <NextStep
                            getNextSteps={getNextSteps}
                            inputGroup={inputGroup}
                            inputGroups={inputGroups}
                            setInputGroups={setInputGroups}
                            index={index}
                            />
                        </div>
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>   
    )
}

export default StepNonLLMAPI