import { useContext, useEffect, useState } from "react"
import AuthenticateEmail from "./AuthenticateEmail"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { Button } from "components/ui/button"
import { channelService } from "api"
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from "components/NewDesignComponents/PageLayout"
import ChannelCard from "./ChannelCard"
import SearchItems from "components/NewDesignComponents/SearchItems"
import { FilterBar } from "./ChannelFilters"

export default function Channel() {

    const [emailChannels, setEmailChannels] = useState([])
    const [channelDialog, setChannelDialog] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filteredChannels, setFilteredChannels] = useState([])
    const [selectType, setSelectType] = useState('')


    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("email-auth-success");
    const statusCode = searchParams.get("statusCode");

    const fetchEmailChannels = () => {
        channelService.getChannels(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            setEmailChannels(res.data)
            setFilteredChannels(res.data)
        }).catch(error => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchEmailChannels()

        if (success === 'false') {
            if (statusCode === '403' || statusCode === 403) {
                addNewNotifcation('Error. Please check your email configuration', 'danger')
            } else if (statusCode === '409' || statusCode === 409) {
                addNewNotifcation('Error. Email already subscribed', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        } else if (success === 'true') {
            setChannelDialog(true)
        }
    }, [])

    const handleFilterChannels = (searchTerm) => {
        const filteredItems = searchTerm? emailChannels.filter(channel =>
           ( channel.topic.toLowerCase().includes(searchTerm.toLowerCase()) ||
            channel.type.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectType === 'All' || channel.type === selectType) // search within selected type if not All
            ) : emailChannels;

        setFilteredChannels(filteredItems)
    }

    return (
        <PageLayout>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Connect your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Engagement Channels
                    </PageSubTitle>
                    <PageDescription>
                        Unify and optimize your communication channels with AI-driven engagement. Seamlessly connect with customers, partners, and teams to deliver personalized, impactful interactions across your enterprise.
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setChannelDialog(true)} className='w-full'> Add new channel </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent loading={loading}>
                <PageContentHeader>
                    <PageContentHeaderTitle>Channel Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Engagement Channels</PageContentHeaderText>
                </PageContentHeader>
                {/* content */}
                <div className="w-full mx-auto p-2 md:p-6">
                    <div className="space-y-4">
                        <div className="mb-6 flex flex-col sm:flex-row gap-4 items-center">
                            <div className="flex-grow">
                                <SearchItems items={emailChannels} handleFilterItems={handleFilterChannels} placeholder="Search Channels..." />
                            </div>
                            <FilterBar
                            allListings={emailChannels}
                            setListings={setFilteredChannels}
                            selectType={selectType}
                            setSelectType={setSelectType}
                            />
                        </div>
                        {filteredChannels.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredChannels.map((item) => (
                                    <ChannelCard key={item._id} channel={item} setEmailChannels={setEmailChannels} />
                                ))}
                            </div>
                        ) : (
                            <div className='flex flex-grow justify-center items-center'>
                                <span className="text-lg font-semibold mb-2">No channels Found</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* add new channel dialog */}
                <AuthenticateEmail isOpen={channelDialog} setOpenEmailModal={setChannelDialog} setEmailChannels={setEmailChannels} />

            </PageContent>
        </PageLayout>
    )
}
