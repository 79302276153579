/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { assistantInstanceService, opcodeAssistantService } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import { Step, StepsContainer } from 'components/NewDesignComponents/Steps';
import AssistantForm from './AssistantForm';
import {  Bot, MoreVertical, Settings, Trash2 } from 'lucide-react'
import { AssistantCombobox, DisplayOpCodeAssistant } from 'pages/Dashboard/EmailExtraction/OpcodeCard';
import { Label } from 'components/ui/label';
import MultiSelectComponent from 'components/ui/MultiSelect';
import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';
import { Context } from 'context/GlobalState';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import AlertModal from 'components/Alertmodal';
import { formatDateToDDMMYYY } from 'utils/timeUtils';
import { getOrganizationMember } from 'utils/organizationUtils';
import { OrganizationContext } from 'context/OrganizationContext';
import ManageAssistantDialog from './UpdateAssistant';
import SearchItems from 'components/NewDesignComponents/SearchItems';

export default function Assistants() {

    const [assistantInstances, setAssistantInstances] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [filteredInstances, setFilteredInstances] = useState([])
    const { oragID, subOragID } = useParams()


    useEffect(() => {

        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            setAssistantInstances(res.data)
            setFilteredInstances(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    const handleFilterAssistantInstances = (searchTerm) => {
        const filteredItems = searchTerm? assistantInstances.filter(instance =>
            instance.title.toLowerCase().includes(searchTerm.toLowerCase())
            ) : assistantInstances;

        setFilteredInstances(filteredItems)
    }

    return (
        <PageLayout>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Build your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Agentic Workforce
                    </PageSubTitle>
                    <PageDescription>
                        Empower your business with AI-driven assistants that work alongside your team. Automate tasks, enhance decision-making, and drive productivity with a workforce built for the future.
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setShow(true)} className='w-full'> Add new assistant </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent loading={loading}>
                <PageContentHeader>
                    <PageContentHeaderTitle>Assistant Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Agentic AI Assistants</PageContentHeaderText>
                </PageContentHeader>
                <div className="w-full mx-auto p-2 md:p-6">
                    <div className="space-y-4">
                        <SearchItems items={assistantInstances} handleFilterItems={handleFilterAssistantInstances} placeholder='Search Assistants...' />
                        {filteredInstances.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredInstances?.map((item) => (
                                    <AssistantCard
                                    key={item._id}
                                    onManage={() => {
                                        setSelectedCard(item)
                                        setUpdateModal(true)
                                    }}
                                    {...item} setAssistantInstances={setAssistantInstances} />
                                ))}
                            </div>
                        ) : (
                            <div className='flex flex-grow justify-center items-center'>
                                <span className="text-lg font-semibold mb-2">No Activated Assistant Found </span>
                            </div>
                        )}
                    </div>
                </div>

                {/* create new assistant dialog */}
                <CreateNewAssistantDialog show={show} setShow={setShow} setAssistantInstances={setAssistantInstances} />

                {/* update assistant dialog */}
                <ManageAssistantDialog show={updateModal} setShow={setUpdateModal} selectedCard={selectedCard} setAssistantInstances={setAssistantInstances} />

            </PageContent>
        </PageLayout>
    )
}

const AssistantCard = ({ _id, title, status, created, createdBy, onManage, setAssistantInstances }) => {

    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)
    const [createdByName, setCreatedByName] = useState('')

    const navigate = useNavigate()
    const { selectedOrganization } = useContext(OrganizationContext)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    const handleDeleteAssistant = async () => {

        setDelBtn(true)

        
        try {
            const delObj = {organizationId: oragID, subOrganizationId: subOragID, assistantId: _id}
            await assistantInstanceService.deleteAssistant(delObj)
            
            // delete assistant in redis (project o)
            const delOpcodeAssistantObj = {org_id: oragID, sub_org_id: subOragID, assistant_name: _id}
            await opcodeAssistantService.deleteOpcodeAssistant(delOpcodeAssistantObj)

            addNewNotifcation('Assistant deleted successfully', 'success')
            setAssistantInstances(prev => prev.filter(item => item._id !== _id))
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }finally {
            setDelBtn(false)
            setDelModal(false)
        }

    }

    useEffect(() => {
        getOrganizationMember(createdBy, selectedOrganization)
        .then((name) => {
            setCreatedByName(name)
        }).catch((error) => {
            console.log('error: ', error);
        })
    }, [])

    return (
        <Card>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle onClick={() => navigate(`${_id}`)} className='cursor-pointer hover:underline'>{title}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={onManage}>
                                <Settings className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full size-16 flex justify-center items-center bg-[#b1092a]'>
                        <Bot size={28} color='white'/>
                    </span>
                    <ul className="flex flex-col">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground'>Created on: <span>{formatDateToDDMMYYY(created)}</span> </li>
                        <li className='text-sm text-muted-foreground'>Created by: <span>{createdByName || '-'}</span> </li>
                        <li className='text-sm text-muted-foreground'>Status: <span> {status} </span> </li>
                    </ul>
                </div>
            </CardContent>

            {/* delete collection modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteAssistant} />
        </Card>
    )
}

const CreateNewAssistantDialog = ({ show, setShow, setAssistantInstances }) => {
    const [btnLoading, setBtnLoading] = useState(false)

    const [assistantForm, setAssistantForm] = useState({title: '', description: ''})

    const [opcodes, setOpcodes] = useState([]);
    const [selectedOpcodes, setSelectedOpcodes] = useState([]);
    const [selectDefaultOpcode, setSelectDefaultOpcode] = useState(null);
    const [fetchOpCodesLoading, setFetchOpCodesLoading] = useState(true);
    const [savedOpcodes, setSavedOpcodes] = useState([]);


    const { oragID, subOragID }= useParams()
    const { addNewNotifcation }= useContext(Context)    

    const getOpCodes = () => {

        setFetchOpCodesLoading(true)

        opCodeService.getOpCodesByOrgSubOrg(oragID, subOragID)
        .then((res) => {
            setOpcodes(res.data.OpCodes.map(item => ({value: item, label: item})))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setFetchOpCodesLoading(false)
        })
    }


    const handleCustomNext = (currentStep, handleNext) => {
        if (currentStep === 1 && (!assistantForm.title || !assistantForm.description)) {
            return addNewNotifcation('Please fill in all fields', 'warning')
        }

        handleNext()
    }

    const opcodeSelect = (items) => {
        setSelectedOpcodes(items)
        if (savedOpcodes.some(opcode => opcode.default === 'true')) {
            const defaultOpcode = savedOpcodes.find(opcode => opcode.default === 'true')
            const newOpcodes = items.map(item => ({name: item, default: 'false'}))
            newOpcodes.push(defaultOpcode)
            setSavedOpcodes(newOpcodes)
        } else {
            setSavedOpcodes(items.map(item => ({name: item, default: 'false'})))
        }
    }

    const handleSelectDefaultOpcode = (item) => {
        console.log('item: ', item);
        
        setSelectDefaultOpcode(item)
        if (savedOpcodes.some(opcode => opcode.default === 'true')) {
            setSavedOpcodes(prev => prev.map(opcode => opcode.default === 'true' ? {name: item.value, default: 'true'} : {...opcode}))
        } else {
            setSavedOpcodes(prev => ([...prev, {name: item.value, default: 'true'}]))
        }
    }

    const handleCreateAssistantAndOpcode = async () => {
        if (selectedOpcodes.length <= 0 || !selectDefaultOpcode?.value) {
            return addNewNotifcation('Please fill opcodes fields', 'warning')
        }

        try {
            setBtnLoading(true)

            const updateOrg = {
                title: assistantForm.title,
                description: assistantForm.description,
                status: 'active',
                organizationId: oragID,
                subOrganizationId: subOragID,
            }

            // create assistant instance
            const addAssistantRes = await assistantInstanceService.addAssistantInstance(updateOrg)
            console.log('addAssistantRes: ', addAssistantRes);

            const opcodeAssistantObj = {
                "org_id": oragID,
                "sub_org_id": subOragID,
                "assistant_name": addAssistantRes.data._id,
                "description": "...",
                "opcodes": selectedOpcodes,
                "default_opcode": selectDefaultOpcode.value
            }
            // create assistant in redis
            await opcodeAssistantService.createOpcodeAssistant(opcodeAssistantObj)

            addNewNotifcation('Assistant created successfully', 'success')
            setAssistantInstances(prev => ([...prev, addAssistantRes.data]))
            setShow(false)
        } catch (error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        } finally {
            setBtnLoading(false)
        }

    }

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full min-h-[500px] max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create new assistant</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new assistant and link it to an opcode.</DialogDescription>
                    </DialogHeader>

                    <div className='flex flex-col mt-4 min-h-[400px]'>
                        <div className='flex flex-col flex-grow'>
                            <StepsContainer
                            className='flex flex-col flex-grow '
                            submitDisabled={btnLoading}
                            customNext={handleCustomNext}
                            onSubmit={handleCreateAssistantAndOpcode}
                            useEffectCallBack={(currentStep) => {
                                if (currentStep === 2) {
                                    getOpCodes()
                                }
                            }}
                            >

                                <Step title='new'>
                                    <AssistantForm form={assistantForm} setForm={setAssistantForm} />
                                </Step>

                                <Step title='new2'>
                                    <div className="space-y-4">
                                        <div className='flex flex-col gap-2'>
                                            <Label> Opcodes </Label>
                                            <MultiSelectComponent items={opcodes} onItemsSelect={opcodeSelect} selectedItems={selectedOpcodes} disabled={fetchOpCodesLoading} />
                                        </div>
                
                                        <div className='flex flex-col gap-2'>
                                            <Label> Default opcode </Label>
                                            <AssistantCombobox items={opcodes} setter={handleSelectDefaultOpcode} selectedValue={selectDefaultOpcode} disabled={fetchOpCodesLoading} />

                                            <div className='mt-3'>
                                                <h3 className="mb-2 font-semibold"> Selected opCodes:</h3>
                                                <DisplayOpCodeAssistant opcodes={savedOpcodes} />
                                            </div>
                                        </div>
                                    </div>
                                </Step>
                            </StepsContainer>
                        
                        </div>
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}