import React, { useState, useEffect } from "react"
import { Button } from "components/ui/button"
import { ChevronDownIcon } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
//   DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"

export default function OutputMapSelector({ onOutputMapChange, previousOutputs, inputGroup }) {

    const [selectedOutputs, setSelectedOutputs] = useState([])

    useEffect(() => {
        setSelectedOutputs([])
    
    }, [inputGroup?.step_id])
    

    useEffect(() => {
        if (selectedOutputs.length === 0) {
          onOutputMapChange(null)
        } else {
          const outputMap = selectedOutputs.reduce(
            (acc, output) => {
              acc[output] = output
              return acc
            },
            {} ,
          )
    
          onOutputMapChange(outputMap)
        }
      }, [selectedOutputs])
    
      const handleOutputToggle = (output) => {
        setSelectedOutputs((prev) => (prev.includes(output) ? prev.filter((item) => item !== output) : [...prev, output]))
      }
    

    return (
        <div className="w-full">
            <div className="flex items-center space-x-2">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="w-full justify-between">
                            <span className="truncate">
                                {selectedOutputs.length > 0
                                    ? `Outputs: ${selectedOutputs.length} selected`
                                    : "Select outputs for mapping"}
                            </span>
                            <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>Previous Outputs</DropdownMenuLabel>
                        {/* <DropdownMenuSeparator /> */}
                        {previousOutputs.map((output) => (
                            <DropdownMenuCheckboxItem
                            key={output.stepId}
                            checked={selectedOutputs.includes(output.prevOutp.value)}
                            onCheckedChange={() => handleOutputToggle(output.prevOutp.value)}
                            >
                                {output.prevOutp.label}
                            </DropdownMenuCheckboxItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    )
}

