import React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"

export function FilterBar({ selectType, setSelectType, allListings, setListings }) {
    const instructionTypes = [
        {name: 'All', value: 'All'},
        {name: 'Email', value: 'Email'},
        {name: 'Web Chat', value: 'Webchat'},
    ]

    const onChange = (value) => {
        setSelectType(value)
        if (value === 'All') {
            setListings(allListings)
        } else {
            setListings(allListings.filter(item => item.type === value))
        }
    }

    return (
        <Select onValueChange={onChange} value={selectType}>
            <SelectTrigger className="w-[180px] bg-white" >
                <SelectValue placeholder="Filter channel type" />
            </SelectTrigger>
            <SelectContent>
                {instructionTypes.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                        {type.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}
